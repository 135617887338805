@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    text-decoration: none;
}
body{
  width: 100vw;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav {
  position: fixed;
  top: 30;
  right: 0;
  margin-top: 20px;
  left: 0;
  display: flex;
  justify-content: space-between;
  display: flex;
  z-index: 100;
}
.nav_controller{
  display: flex;
  padding-left: 20px;
  flex-direction: row;
  align-items: center;
}
.nav_controller_btn{
  margin-left: 10px;
  margin-right: 10px;
  background-color: #15423F;
  color: white;
  border-color: transparent;
  font-weight: bold;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.pdf_body{
  margin-top: 80px;
  display: flex;
  justify-content: center;
}
.textLayer {
  display: none;
}
.annotationLayer {
  display: none;
}
/*Carousel CSS start*/
.customCardCarouselDiv img{
  border-radius: 20px;
}
.customCardCarouselDiv{
  padding: 0 7.5%;
}
.alice-carousel{
  padding: 1% 3%;
}
.testimonialCardDiv{
  height: 160px !important;  
}
.commentTextAreaDiv{
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis;
}
.commentTextAreaContent{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: -webkit-fill-available;
  max-width: -moz-available;
}
.rcf-loader{
  background:#eee;
  width:400px;
  height:610px;
  position: absolute;
  z-index: 100;
  display: none;
}